import { TextField, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
const DispatchInformation = ({
  payloadValidations,
  payload,
  contactNameRegex,
  setPayload,
  setPayloadValidations,
  emailRegex,
  scacValidate,
}) => {
  return (
    <Grid container spacing={3}>
      {" "}
      <Grid
        xs={12}
        sm={6}
        md={6}
        lg={3}
        xl={3}
        className="FormCreateUser-target-title-grid"
      >
        {" "}
        <Typography className="FormCreateUser-target-title">
          <AssignmentOutlinedIcon className="FormCreateUser-target-icon" />
          Dispatch
        </Typography>
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Dispatch Contact Name"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload?.YardOwner?.DispatchInformation?.Name
              ? payload.YardOwner.DispatchInformation.Name
              : ""
          }
          error={payloadValidations.YardOwner.DispatchInformation.Name}
          onChange={(e) => {
            const contactName = e.target.value;

            // Validar que el nombre de contacto siga la regex
            const isValidContactName = contactNameRegex.test(contactName);

            setPayload({
              ...payload,
              YardOwner: {
                ...payload.YardOwner,
                DispatchInformation: {
                  ...payload.YardOwner.DispatchInformation,
                  Name: contactName,
                },
              },
            });

            setPayloadValidations({
              ...payloadValidations,
              YardOwner: {
                ...payloadValidations.YardOwner,
                DispatchInformation: {
                  ...payloadValidations.YardOwner.DispatchInformation,
                  Name:
                    !isValidContactName || contactName.length > 0
                      ? false
                      : true,
                },
              },
            });
          }}
        />
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Dispatch Email"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload?.YardOwner?.DispatchInformation?.Email
              ? payload.YardOwner.DispatchInformation.Email
              : ""
          }
          error={payloadValidations.YardOwner.DispatchInformation.Email}
          onChange={(e) => {
            const email = e.target.value;

            setPayload({
              ...payload,
              YardOwner: {
                ...payload.YardOwner,
                DispatchInformation: {
                  ...payload.YardOwner.DispatchInformation,
                  Email: email,
                },
              },
            });

            if (!emailRegex.test(email) || email.length === 0) {
              setPayloadValidations({
                ...payloadValidations,
                YardOwner: {
                  ...payloadValidations.YardOwner,
                  DispatchInformation: {
                    ...payloadValidations.YardOwner.DispatchInformation,
                    Email: true,
                  },
                },
              });
            } else {
              setPayloadValidations({
                ...payloadValidations,
                YardOwner: {
                  ...payloadValidations.YardOwner,
                  DispatchInformation: {
                    ...payloadValidations.YardOwner.DispatchInformation,
                    Email: false,
                  },
                },
              });
            }
          }}
        />
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="Dispatch Phone Number"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload?.YardOwner?.DispatchInformation?.PhoneNumber
              ? payload.YardOwner.DispatchInformation.PhoneNumber
              : ""
          }
          placeholder="(123) 456-7890"
          error={payloadValidations.YardOwner.DispatchInformation.PhoneNumber}
          inputProps={{
            maxLength: 10,
            inputMode: "numeric", // Ensures numeric keyboard on mobile devices
          }}
          onChange={(e) => {
            const value = e.target.value;

            // Allow only digits
            if (!/^\d*$/.test(value)) {
              return;
            }

            setPayload({
              ...payload,
              YardOwner: {
                ...payload.YardOwner,
                DispatchInformation: {
                  ...payload.YardOwner.DispatchInformation,
                  PhoneNumber: parseInt(value),
                },
              },
            });

            if (value.length === 10 || value.length === 0) {
              setPayloadValidations({
                ...payloadValidations,
                YardOwner: {
                  ...payloadValidations.YardOwner,
                  DispatchInformation: {
                    ...payloadValidations.YardOwner.DispatchInformation,
                    PhoneNumber: false,
                  },
                },
              });
            } else {
              setPayloadValidations({
                ...payloadValidations,
                YardOwner: {
                  ...payloadValidations.YardOwner,
                  DispatchInformation: {
                    ...payloadValidations.YardOwner.DispatchInformation,
                    PhoneNumber: true,
                  },
                },
              });
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DispatchInformation;
