import React from "react";
import { Container, Paper } from "@mui/material";

import "./CreateUserForm.css";

import TitleForm from "../components/Title/Title";

import FormCreateUser from "../components/Form/FormCreateUser";
export const YardInformationPage = () => {
  return (
    <section className="CreateUserForm-background-justify">
      <Paper elevation={3} className="CreateUserForm-paper-container">
        <TitleForm />
        <FormCreateUser />
      </Paper>
    </section>
  );
};
