import { Button } from "@mui/material";
import "./ContinueInLanding.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const ContinueInLanding = () => {
  function handleClick() {
    window.dispatchEvent(new CustomEvent("logOutRequired"));
  }
  return (
    <Button
      className="CreateUser-ContinueInLanding-button"
      variant="text"
      color="primary"
      endIcon={<ArrowForwardIcon />}
      onClick={(e) => handleClick()}
    >
      Go to landing page
    </Button>
  );
};

export default ContinueInLanding;
