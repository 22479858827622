import { Button } from "@mui/material";
import "./ButtonBack.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const ButtonBack = () => {
  function handleClick() {
    window.history.back();
  }
  return (
    <Button
      className="CreateYardOwner-ButtonBack-button"
      variant="text"
      color="primary"
      startIcon={<ArrowBackIcon />}
      onClick={(e) => handleClick()}
    >
      Back
    </Button>
  );
};

export default ButtonBack;
