// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CreateUser-root-component-container {

    background-color: rgb(30 30 67 / 95%);

    height: 100%;
    display: flex;
    justify-content: center;
}

`, "",{"version":3,"sources":["webpack://./src/root.css"],"names":[],"mappings":"AAAA;;IAEI,qCAAqC;;IAErC,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["body .CreateUser-root-component-container {\n\n    background-color: rgb(30 30 67 / 95%);\n\n    height: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
