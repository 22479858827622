import {
  TextField,
  CircularProgress,
  InputAdornment,
  Button,
} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
const UserInformation = ({
  payloadValidations,
  payload,
  setPayload,
  setPayloadValidations,
  scacValidate,
  setScacValidate,
  handleCheckScacCode,
  handleCleanInformation,
  nameRegex,
  cityDistrictRegex,
  stateProvinceRegex,
  emailRegex,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="SCAC Code"
          variant="outlined"
          fullWidth
          length={5}
          error={payloadValidations.YardOwner.ScacCode}
          value={payload.YardOwner.ScacCode ? payload.YardOwner.ScacCode : ""}
          disabled={scacValidate.loading}
          onChange={(e) => {
            if (e.target.value.length > 4) return;
            setPayload({
              ...payload,
              YardOwner: {
                ...payload.YardOwner,
                ScacCode: e.target.value.toUpperCase(),
              },
            });
            if (e.target.value.length === 4) {
              handleCheckScacCode(e.target.value.toUpperCase());
            } else if (e.target.value.length < 4 && scacValidate.existing) {
              setScacValidate({ existing: false, loading: false });
              handleCleanInformation();
            }
            if (e.target.value.length > 0 && e.target.value.length === 4) {
              setPayloadValidations({
                ...payloadValidations,
                YardOwner: { ...payloadValidations.YardOwner, ScacCode: false },
              });
            } else {
              setPayloadValidations({
                ...payloadValidations,
                YardOwner: { ...payloadValidations.YardOwner, ScacCode: true },
              });
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {scacValidate.loading && (
                  <CircularProgress color="primary" size={20} />
                )}
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>{" "}
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Company Name"
          variant="outlined"
          fullWidth
          error={payloadValidations.YardOwner.CompanyName}
          disabled={scacValidate.existing}
          placeholder="Company Name"
          value={
            payload.YardOwner.CompanyName ? payload.YardOwner.CompanyName : ""
          }
          onChange={(e) => {
            const companyName = e.target.value;

            const isValidCompanyName =
              nameRegex.test(companyName) && !companyName.includes("@");

            setPayload({
              ...payload,
              YardOwner: { ...payload.YardOwner, CompanyName: companyName },
            });

            setPayloadValidations({
              ...payloadValidations,
              YardOwner: {
                ...payloadValidations.YardOwner,
                CompanyName: !isValidCompanyName,
              },
            });
          }}
        />
      </Grid>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Contact Name"
          variant="outlined"
          fullWidth
          disabled
          error={payloadValidations.NewUser.UserName}
          value={payload.NewUser.UserName ? payload.NewUser.UserName : ""}
          onChange={(e) => {
            const contactName = e.target.value;

            // Validar que el nombre de contacto siga la regex
            const isValidContactName =
              nameRegex.test(contactName) && !contactName.includes("@");
            setPayload({
              ...payload,
              NewUser: {
                ...payload.NewUser,
                UserName: contactName,
              },
            });

            setPayloadValidations({
              ...payloadValidations,
              NewUser: {
                ...payloadValidations.NewUser,
                UserName:
                  !isValidContactName || contactName.length > 0 ? false : true,
              },
            });
          }}
        />
      </Grid>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Email"
          disabled
          variant="outlined"
          fullWidth
          error={payloadValidations.NewUser.Email}
          value={payload.NewUser.Email ? payload.NewUser.Email : ""}
          onChange={(e) => {
            const email = e.target.value;

            // Validate that the email follows the regex
            const isValidEmail = emailRegex.test(email);

            setPayload({
              ...payload,
              NewUser: {
                ...payload.NewUser,
                Email: email,
              },
            });

            setPayloadValidations({
              ...payloadValidations,
              NewUser: {
                ...payloadValidations.NewUser,
                Email: !isValidEmail,
              },
            });
          }}
        />
      </Grid>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Phone Number"
          placeholder="(123) 456-7890"
          variant="outlined"
          fullWidth
          value={payload.NewUser.PhoneNumber || ""}
          error={payloadValidations.NewUser.PhoneNumber}
          inputProps={{
            maxLength: 10,
            inputMode: "numeric",
          }}
          onChange={(e) => {
            const value = e.target.value;

            if (!/^\d*$/.test(value)) {
              return;
            }

            setPayload({
              ...payload,
              NewUser: {
                ...payload.NewUser,
                PhoneNumber: value,
              },
            });

            if (value.length === 10) {
              setPayloadValidations({
                ...payloadValidations,
                NewUser: {
                  ...payloadValidations.NewUser,
                  PhoneNumber: false,
                },
              });
            }
          }}
        />
      </Grid>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Address line"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload.YardOwner.Address.AddressLine
              ? payload.YardOwner.Address.AddressLine
              : ""
          }
          error={payloadValidations.YardOwner.Address.AddressLine}
          onChange={(e) => {
            setPayload({
              ...payload,
              YardOwner: {
                ...payload.YardOwner,
                Address: {
                  ...payload.YardOwner.Address,
                  AddressLine: e.target.value,
                },
              },
            });
            if (e.target.value.length > 0) {
              setPayloadValidations({
                ...payloadValidations,
                YardOwner: {
                  ...payloadValidations.YardOwner,
                  Address: {
                    ...payloadValidations.YardOwner.Address,
                    AddressLine: false,
                  },
                },
              });
            }
          }}
        />
      </Grid>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="City District"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload.YardOwner.Address.City ? payload.YardOwner.Address.City : ""
          }
          error={payloadValidations.YardOwner.Address.City}
          onChange={(e) => {
            const cityDistrict = e.target.value;

            // Validate that the city district follows the regex
            const isValidCityDistrict = cityDistrictRegex.test(cityDistrict);

            setPayload({
              ...payload,
              YardOwner: {
                ...payload.YardOwner,
                Address: {
                  ...payload.YardOwner.Address,
                  City: cityDistrict,
                },
              },
            });

            setPayloadValidations({
              ...payloadValidations,
              YardOwner: {
                ...payloadValidations.YardOwner,
                Address: {
                  ...payloadValidations.YardOwner.Address,
                  City: !isValidCityDistrict,
                },
              },
            });
          }}
        />
      </Grid>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="State/Province"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload.YardOwner.Address.State
              ? payload.YardOwner.Address.State
              : ""
          }
          error={payloadValidations.YardOwner.Address.State}
          onChange={(e) => {
            const stateProvince = e.target.value;

            // Validate that the state/province follows the regex
            const isValidStateProvince = stateProvinceRegex.test(stateProvince);

            setPayload({
              ...payload,
              YardOwner: {
                ...payload.YardOwner,
                Address: {
                  ...payload.YardOwner.Address,
                  State: stateProvince,
                },
              },
            });

            setPayloadValidations({
              ...payloadValidations,
              YardOwner: {
                ...payloadValidations.YardOwner,
                Address: {
                  ...payloadValidations.YardOwner.Address,
                  State: !isValidStateProvince,
                },
              },
            });
          }}
        />
      </Grid>
      <Grid lg={6} xl={6} md={6} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Postal Code"
          variant="outlined"
          fullWidth
          inputProps={{
            maxLength: 5,
            inputMode: "numeric",
          }}
          disabled={scacValidate.existing}
          value={
            payload.YardOwner.Address.ZipCode
              ? payload.YardOwner.Address.ZipCode
              : ""
          }
          onChange={(e) => {
            const number = parseInt(e.target.value);
            if (!isNaN(number) || e.target.value === "") {
              setPayload({
                ...payload,
                YardOwner: {
                  ...payload.YardOwner,
                  Address: {
                    ...payload.YardOwner.Address,
                    ZipCode: number,
                  },
                },
              });
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
export default UserInformation;
