import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const createYardOwner = async (payload) => {
  const config = {
    headers,
    url: process.env.REACT_APP_CREATE_YARD_OWNER,
    data: (payload = { payload }),
    method: "POST",
    nameEvent: "CreateYardOwner-createYardOwner",
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getYardOwnerByScac = async (scac) => {
  const config = {
    headers,
    url: process.env.REACT_APP_GET_YARD_OWNER_BY_SCAC,
    method: "GET",
    params: {
      ScacCode: scac,
    },
    nameEvent: "CreateYardOwner-getYardOwnerByScac",
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
