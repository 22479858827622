// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CreateUserForm-paper-container {
    border-radius: 25px;
    padding: 10% 10% 10% 10%;
}



@media screen and (max-width: 1200px) {
    body .CreateUserForm-background-justify {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 10% 10% 10% 10%;
    }


}

@media screen and (min-width: 1200px) {
    body .CreateUserForm-background-justify {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 10% 20% 10% 20%;
    }

}`, "",{"version":3,"sources":["webpack://./src/containers/CreateUserForm.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,wBAAwB;AAC5B;;;;AAIA;IACI;QACI,mBAAmB;QACnB,uBAAuB;QACvB,aAAa;QACb,wBAAwB;IAC5B;;;AAGJ;;AAEA;IACI;QACI,mBAAmB;QACnB,uBAAuB;QACvB,aAAa;QACb,wBAAwB;IAC5B;;AAEJ","sourcesContent":["body .CreateUserForm-paper-container {\n    border-radius: 25px;\n    padding: 10% 10% 10% 10%;\n}\n\n\n\n@media screen and (max-width: 1200px) {\n    body .CreateUserForm-background-justify {\n        align-items: center;\n        justify-content: center;\n        display: flex;\n        padding: 10% 10% 10% 10%;\n    }\n\n\n}\n\n@media screen and (min-width: 1200px) {\n    body .CreateUserForm-background-justify {\n        align-items: center;\n        justify-content: center;\n        display: flex;\n        padding: 10% 20% 10% 20%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
