import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import "./Title.css";
const TitleForm = () => {
  return (
    <>
      <Grid
        container
        spacing={3}
        className="TitleFormCreateUser-grid-container"
      >
        <Grid xs={12} className="TitleFormCreateUser-grid-image-draypower">
          <img
            src={process.env.REACT_APP_URL_GET_DRAYPOWER_IMAGE}
            alt="Draypower Logo"
            className="TitleFormCreateUser-image-draypower"
          />
        </Grid>{" "}
        <Grid xs={12}>
          <Typography className="TitleFormCreateUser-typeOfUser-text">
            Form to Yard Owners
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography className="TitleFormCreateUser-welcome-text">
            Welcome to Draypower System
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography className="TitleFormCreateUser-description-text">
            Take control of your yard and manage your inventory with tools that
            help you make better decisions.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default TitleForm;
