import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Typography,
  Divider,
  Button,
  CircularProgress,
  Backdrop,
  Paper,
} from "@mui/material";
import {
  createYardOwner,
  getYardOwnerByScac,
} from "../../services/yardOwnerServices";
import { useSnackBar } from "../../context/SnackBarContext";
import BillingInformation from "../BillingInformation/BillingInformation";
import DispatchInformation from "../DispatchInformation/DispatchInformation";
import STInformation from "../STInformation/STInformation";
import UserInformation from "../UserInformation/UserInformation";
import YardsComponent from "../YardsComponent/YardsComponent";
import Cookies from "universal-cookie";
import "./FormCreateUser.css";
const FormCreateUser = () => {
  const cookies = new Cookies();
  const { handleRenderSnackBar } = useSnackBar();
  const [scacValidate, setScacValidate] = React.useState({
    existing: false,
    loading: false,
  });
  const [creatingUser, setCreatingUser] = React.useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
  const contactNameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
  const cityDistrictRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
  const stateProvinceRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
  const [payload, setPayload] = React.useState({
    YardOwner: {
      ScacCode: null,
      CompanyName: null,
      Address: {
        AddressLine: null,
        City: null,
        State: null,
        ZipCode: null,
      },
      BillingInformation: {
        Name: null,
        Email: null,
        PhoneNumber: null,
      },
      STInformation: {
        Name: "",
        Email: "",
        PhoneNumber: null,
      },
      DispatchInformation: {
        Name: "",
        Email: "",
        PhoneNumber: null,
      },
      Yards: [
        {
          name: null,
          spotsAvailable: null,
          spotsToRent: null,
        },
      ],
    },
    NewUser: {
      UserName: null,
      Email: null,
      PhoneNumber: null,
    },
    ResourToAuthorize: "YMSAdmin",
  });

  const [payloadValidations, setPayloadValidations] = React.useState({
    YardOwner: {
      ScacCode: false,
      CompanyName: false,
      Address: {
        AddressLine: false,
        City: false,
        State: false,
      },
      BillingInformation: {
        Name: false,
        Email: false,
        PhoneNumber: false,
      },
      STInformation: {
        Name: false,
        Email: false,
        PhoneNumber: false,
      },
      DispatchInformation: {
        Name: false,
        Email: false,
        PhoneNumber: false,
      },
      YardsValidation: false,
    },
    NewUser: {
      UserId: false,
      UserName: false,
      Email: false,
    },
  });

  function validatePayload() {
    let validations = true;

    if (!payload.YardOwner.ScacCode) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: { ...prevValidations.YardOwner, ScacCode: true },
      }));
      validations = false;
    }
    if (!payload.YardOwner.CompanyName) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: { ...prevValidations.YardOwner, CompanyName: true },
      }));
      validations = false;
    }
    if (!payload.YardOwner.Address.AddressLine) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: {
          ...prevValidations.YardOwner,
          Address: { ...prevValidations.YardOwner.Address, AddressLine: true },
        },
      }));
      validations = false;
    }
    if (!payload.YardOwner.Address.City) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: {
          ...prevValidations.YardOwner,
          Address: { ...prevValidations.YardOwner.Address, City: true },
        },
      }));
      validations = false;
    }
    if (!payload.YardOwner.Address.State) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: {
          ...prevValidations.YardOwner,
          Address: { ...prevValidations.YardOwner.Address, State: true },
        },
      }));
      validations = false;
    }
    if (!payload.YardOwner.BillingInformation.Name) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: {
          ...prevValidations.YardOwner,
          BillingInformation: {
            ...prevValidations.YardOwner.BillingInformation,
            Name: true,
          },
        },
      }));
      validations = false;
    }
    if (!payload.YardOwner.BillingInformation.Email) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: {
          ...prevValidations.YardOwner,
          BillingInformation: {
            ...prevValidations.YardOwner.BillingInformation,
            Email: true,
          },
        },
      }));
      validations = false;
    }
    if (!payload.YardOwner.BillingInformation.PhoneNumber) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        YardOwner: {
          ...prevValidations.YardOwner,
          BillingInformation: {
            ...prevValidations.YardOwner.BillingInformation,
            PhoneNumber: true,
          },
        },
      }));
      validations = false;
    }
    if (!payload.NewUser.UserName) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        NewUser: { ...prevValidations.NewUser, UserName: true },
      }));
      validations = false;
    }
    if (!payload.NewUser.Email) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        NewUser: { ...prevValidations.NewUser, Email: true },
      }));
      return false;
    }

    return validations;
  }

  function handleEvaluateYards(newPayload) {
    if (newPayload.YardOwner.Yards && newPayload.YardOwner.Yards.length > 0) {
      newPayload.YardOwner.Yards.forEach((yard) => {
        if (!yard.name || yard.name === "") {
          //delte from array
          const index = newPayload.YardOwner.Yards.indexOf(yard);
          newPayload.YardOwner.Yards.splice(index, 1);
        }
      });
      if (newPayload.YardOwner.Yards.length === 0) {
        newPayload.YardOwner.Yards = null;
      }
      return newPayload;
    }
  }
  function handleCheckScacCode(scac) {
    setScacValidate({ existing: false, loading: true });
    getYardOwnerByScac(scac)
      .then((response) => {
        if (response) {
          setScacValidate({ existing: false, loading: false });
          handleRenderSnackBar(
            `Yard Owner with ${scac} SCAC Code already exists`,
            "error"
          );

          setPayload({
            ...payload,
            YardOwner: {
              ...payload.YardOwner,
              ScacCode: "",
            },
          });
          return;
        }
        setScacValidate({ existing: false, loading: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function handleCreateUserApi() {
    const validations = validatePayload();
    if (!validations) return;
    let newPayload = handleEvaluateYards(payload);
    setCreatingUser(true);
    createYardOwner(newPayload).then((response) => {
      if (response.Errors) {
        const errors = response.Errors.map((error) => error.ErrorMessage);
        handleRenderSnackBar(errors, "error");
        setCreatingUser(false);
        return;
      }
      handleRenderSnackBar("Yard Owner created successfully", "success");
      localStorage.removeItem("YardOwnerInComing");
      setTimeout(() => {
        window.location.href = `${window.location.origin}/landing-page/home`;
      }, 3000);
    });
  }

  function handleCleanInformation() {
    setPayload({
      ...payload,
      YardOwner: {
        ...payload.YardOwner,
        CompanyName: null,
        Address: {
          AddressLine: null,
          City: null,
          State: null,
          ZipCode: null,
        },
        BillingInformation: {
          Name: null,
          Email: null,
          PhoneNumber: null,
        },
        STInformation: {
          Name: null,
          Email: null,
          PhoneNumber: null,
        },
        DispatchInformation: {
          Name: null,
          Email: null,
          PhoneNumber: null,
        },
        Yards: [
          {
            name: null,
            spotsAvailable: null,
            spotsToRent: null,
          },
        ],
      },
    });
  }

  function handleSetb2cInfoToUser(e) {
    setPayload({
      ...payload,
      NewUser: {
        UserId: e.detail?.idTokenClaims.sub,
        UserName: e.detail?.name,
        Email: e.detail?.username,
      },
    });
    setCreatingUser(false);
  }

  React.useEffect(() => {
    setCreatingUser(true);
    window.addEventListener("popstate", () => {
      localStorage.removeItem("YardOwnerInComing");
    });
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("YardOwnerInComing");
    });
    if (cookies.get("sessionCosmos")) {
      handleRenderSnackBar(
        "You are alredy has an existing user, please log out to create a new user as a Yard Owner or contact to support team to help you with this issue.",
        "error"
      );
      setTimeout(() => {
        window.history.back();
      }, 6000);
    } else {
      window.addEventListener("userInB2C", handleSetb2cInfoToUser);
      window.dispatchEvent(new CustomEvent("getUserData"));
      localStorage.setItem("YardOwnerInComing", "Yard Owner");
    }
  }, []);
  return (
    <>
      {creatingUser && (
        <Backdrop
          open={creatingUser}
          className="FormCreateUser-backdrop-loading-create"
        >
          <CircularProgress
            thickness={5}
            className="FormCreateUser-circular-loading-create"
          />
        </Backdrop>
      )}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Typography className="FormCreateUser-title">
            Fill the information below to create a Yard Owner Account
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography className="FormCreateUser-title-red">
            YARD OWNER INFORMATION
          </Typography>
        </Grid>
        <Grid xs={12}>
          <UserInformation
            payloadValidations={payloadValidations}
            payload={payload}
            setPayload={setPayload}
            setPayloadValidations={setPayloadValidations}
            scacValidate={scacValidate}
            setScacValidate={setScacValidate}
            handleCheckScacCode={handleCheckScacCode}
            handleCleanInformation={handleCleanInformation}
            nameRegex={nameRegex}
            emailRegex={emailRegex}
            cityDistrictRegex={cityDistrictRegex}
            stateProvinceRegex={stateProvinceRegex}
          />
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Typography className="FormCreateUser-title">
            Contact Information
          </Typography>
        </Grid>
        <Paper elevation={3} className="FormCreateUser-paper">
          <Grid xs={12}>
            <BillingInformation
              payload={payload}
              setPayload={setPayload}
              payloadValidations={payloadValidations}
              setPayloadValidations={setPayloadValidations}
              scacValidate={scacValidate}
              contactNameRegex={contactNameRegex}
              emailRegex={emailRegex}
            />
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <STInformation
              payload={payload}
              setPayload={setPayload}
              payloadValidations={payloadValidations}
              setPayloadValidations={setPayloadValidations}
              scacValidate={scacValidate}
              contactNameRegex={contactNameRegex}
              emailRegex={emailRegex}
            />
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <DispatchInformation
              payload={payload}
              setPayload={setPayload}
              payloadValidations={payloadValidations}
              setPayloadValidations={setPayloadValidations}
              scacValidate={scacValidate}
              contactNameRegex={contactNameRegex}
              emailRegex={emailRegex}
            />
          </Grid>
        </Paper>{" "}
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Typography className="FormCreateUser-title">
            Yards Information
          </Typography>
        </Grid>
        <Paper elevation={3} className="FormCreateUser-paper">
          {" "}
          <Grid xs={12}>
            <YardsComponent
              payload={payload}
              setPayload={setPayload}
              payloadValidations={payloadValidations}
              setPayloadValidations={setPayloadValidations}
            />
          </Grid>
        </Paper>
        <Grid xs={12}>
          <Button
            variant="contained"
            fullWidth
            className="FormCreateUser-button"
            onClick={() => handleCreateUserApi()}
          >
            Create Yard Owner Account
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FormCreateUser;
