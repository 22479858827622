// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CreateUser-ContinueInLanding-button {
    position: fixed;
    top: 5%;
    right: 1%;
    transform: translate(0, -50%);
    z-index: 33;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/ContinueInLanding/ContinueInLanding.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,SAAS;IACT,6BAA6B;IAC7B,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":["body .CreateUser-ContinueInLanding-button {\n    position: fixed;\n    top: 5%;\n    right: 1%;\n    transform: translate(0, -50%);\n    z-index: 33;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
