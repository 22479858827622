// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CreateYardOwner-YardComponent-delete-button {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

body .CreateYardOwner-YardComponent-add-button{
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/YardsComponent/YardComponent.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["body .CreateYardOwner-YardComponent-delete-button {\n    color: red;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\nbody .CreateYardOwner-YardComponent-add-button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
