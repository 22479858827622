import { TextField, Button } from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import "./YardComponent.css";
const YardComponent = ({
  payload,
  setPayload,
  payloadValidations,
  setPayloadValidations,
}) => {
  function handleInputChange(index, field, value) {
    const newYards = [...payload.YardOwner.Yards];
    newYards[index][field] = value;
    setPayload({
      ...payload,
      YardOwner: {
        ...payload.YardOwner,
        Yards: newYards,
      },
    });
  }

  function handleAddYard() {
    const newYards = [
      ...(payload.YardOwner.Yards ? payload.YardOwner.Yards : []),
    ];
    newYards.push({
      name: "",
      spotsAvailable: null,
      spotsToRent: null,
    });
    setPayload({
      ...payload,
      YardOwner: {
        ...payload.YardOwner,
        Yards: newYards,
      },
    });
  }

  function handleDeleteYard(index) {
    const newYards = [...payload.YardOwner.Yards];
    newYards.splice(index, 1);
    setPayload({
      ...payload,
      YardOwner: {
        ...payload.YardOwner,
        Yards: newYards,
      },
    });
  }

  return (
    <>
      <Grid container spacing={2}>
        {payload.YardOwner.Yards &&
          payload.YardOwner.Yards.map((yard, index) => (
            <React.Fragment key={index}>
              <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  label="Yard Name"
                  variant="outlined"
                  fullWidth
                  error={
                    payloadValidations.YardOwner.YardsValidation && !yard.name
                      ? true
                      : false
                  }
                  value={yard.name}
                  onChange={(e) => {
                    handleInputChange(index, "name", e.target.value);
                  }}
                />
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  label="Spots Available"
                  variant="outlined"
                  fullWidth
                  type="number"
                  error={
                    payloadValidations.YardOwner.YardsValidation &&
                    !yard.spotsAvailable
                      ? true
                      : false
                  }
                  value={yard.spotsAvailable}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      return;
                    }
                    handleInputChange(
                      index,
                      "spotsAvailable",
                      parseInt(e.target.value)
                    );
                  }}
                />
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} xl={3}>
                <TextField
                  label="Spots To Rent"
                  variant="outlined"
                  fullWidth
                  type="number"
                  error={
                    payloadValidations.YardOwner.YardsValidation &&
                    !yard.spotsToRent
                      ? true
                      : false
                  }
                  value={yard.spotsToRent}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      return;
                    }
                    if (value <= yard.spotsAvailable) {
                      handleInputChange(
                        index,
                        "spotsToRent",
                        parseInt(e.target.value)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                className="CreateYardOwner-YardComponent-delete-button"
              >
                <Button
                  variant="text"
                  color="error"
                  disabled={payload.YardOwner.Yards.length === 1}
                  onClick={() => handleDeleteYard(index)}
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="CreateYardOwner-YardComponent-add-button"
        >
          <Button
            variant="text"
            color="primary"
            onClick={handleAddYard}
            startIcon={<AddCircle />}
          >
            Add Yard
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default YardComponent;
