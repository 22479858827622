import { YardInformationPage } from "./containers/CreateUserForm";
import { SnackBarProvider } from "./context/SnackBarContext";
import NotificationAlert from "./components/SnackBar/SnackBar";
import ButtonBack from "./components/ButtonBack/ButtonBack";
import BackgroundImage from "./assets/imgs/background/background.png";
import ContinueInLanding from "./components/ContinueInLanding/ContinueInLanding";
import "./root.css";
export default function Root() {
  return (
    <section
      style={{
        height: "fit-content",
        backgroundImage: `url(${BackgroundImage})`, // Aquí se usa la URL de la imagen de fondo
        backgroundSize: "contain",
      }}
    >
      <section className="CreateUser-root-component-container">
        <SnackBarProvider>
          <ButtonBack />
          <YardInformationPage />
          <NotificationAlert />
          <ContinueInLanding />
        </SnackBarProvider>
      </section>
    </section>
  );
}
