import { TextField, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LocalAtmIcon from "@mui/icons-material/LocalAtmOutlined";
const BillingInformation = ({
  payloadValidations,
  payload,
  contactNameRegex,
  setPayload,
  setPayloadValidations,
  emailRegex,
  scacValidate,
}) => {
  return (
    <>
      {" "}
      <Grid container spacing={3}>
        {" "}
        <Grid
          xs={12}
          sm={6}
          md={6}
          lg={3}
          xl={3}
          className="FormCreateUser-target-title-grid"
        >
          {" "}
          <Typography className="FormCreateUser-target-title">
            <LocalAtmIcon className="FormCreateUser-target-icon" />
            Billing
          </Typography>
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
          <TextField
            id="outlined-basic"
            label="Billing Contact Name"
            variant="outlined"
            fullWidth
            disabled={scacValidate.existing}
            value={
              payload.YardOwner.BillingInformation.Name
                ? payload.YardOwner.BillingInformation.Name
                : ""
            }
            error={payloadValidations.YardOwner.BillingInformation.Name}
            onChange={(e) => {
              const contactName = e.target.value;

              // Validar que el nombre de contacto siga la regex
              const isValidContactName = contactNameRegex.test(contactName);

              setPayload({
                ...payload,
                YardOwner: {
                  ...payload.YardOwner,
                  BillingInformation: {
                    ...payload.YardOwner.BillingInformation,
                    Name: contactName,
                  },
                },
              });

              setPayloadValidations({
                ...payloadValidations,
                YardOwner: {
                  ...payloadValidations.YardOwner,
                  BillingInformation: {
                    ...payloadValidations.YardOwner.BillingInformation,
                    Name: !isValidContactName,
                  },
                },
              });
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
          <TextField
            id="outlined-basic"
            label="Billing Email"
            variant="outlined"
            fullWidth
            disabled={scacValidate.existing}
            value={
              payload.YardOwner.BillingInformation.Email
                ? payload.YardOwner.BillingInformation.Email
                : ""
            }
            error={payloadValidations.YardOwner.BillingInformation.Email}
            onChange={(e) => {
              const email = e.target.value;

              setPayload({
                ...payload,
                YardOwner: {
                  ...payload.YardOwner,
                  BillingInformation: {
                    ...payload.YardOwner.BillingInformation,
                    Email: email,
                  },
                },
              });

              if (email.length > 0 && !emailRegex.test(email)) {
                setPayloadValidations({
                  ...payloadValidations,
                  YardOwner: {
                    ...payloadValidations.YardOwner,
                    BillingInformation: {
                      ...payloadValidations.YardOwner.BillingInformation,
                      Email: true,
                    },
                  },
                });
              } else {
                setPayloadValidations({
                  ...payloadValidations,
                  YardOwner: {
                    ...payloadValidations.YardOwner,
                    BillingInformation: {
                      ...payloadValidations.YardOwner.BillingInformation,
                      Email: false,
                    },
                  },
                });
              }
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
          <TextField
            id="outlined-basic"
            label="Billing Phone Number"
            variant="outlined"
            fullWidth
            disabled={scacValidate.existing}
            value={
              payload.YardOwner.BillingInformation.PhoneNumber
                ? payload.YardOwner.BillingInformation.PhoneNumber
                : ""
            }
            placeholder="(123) 456-7890"
            error={payloadValidations.YardOwner.BillingInformation.PhoneNumber}
            inputProps={{
              maxLength: 10,
              inputMode: "numeric", // Ensures numeric keyboard on mobile devices
            }}
            onChange={(e) => {
              const value = e.target.value;

              // Allow only digits
              if (!/^\d*$/.test(value)) {
                return;
              }

              setPayload({
                ...payload,
                YardOwner: {
                  ...payload.YardOwner,
                  BillingInformation: {
                    ...payload.YardOwner.BillingInformation,
                    PhoneNumber: parseInt(value),
                  },
                },
              });

              if (value.length === 10) {
                setPayloadValidations({
                  ...payloadValidations,
                  YardOwner: {
                    ...payloadValidations.YardOwner,
                    BillingInformation: {
                      ...payloadValidations.YardOwner.BillingInformation,
                      PhoneNumber: false,
                    },
                  },
                });
              } else {
                setPayloadValidations({
                  ...payloadValidations,
                  YardOwner: {
                    ...payloadValidations.YardOwner,
                    BillingInformation: {
                      ...payloadValidations.YardOwner.BillingInformation,
                      PhoneNumber: true,
                    },
                  },
                });
              }
            }}
          />
        </Grid>
      </Grid>{" "}
    </>
  );
};

export default BillingInformation;
